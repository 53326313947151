import TableRow, { type TableRowProps } from '@mui/material/TableRow/TableRow'
import type { NavigateOptions } from '@tanstack/react-router'
import type { ReactElement } from 'react'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { TableRowClickable } from './table-row-clickable'

type TableRowLinkProps = TableRowProps & {
  href?: NavigateOptions
}

export function TableRowLink({ href, ...passedProps }: TableRowLinkProps): ReactElement {
  const navigateToHref = useNavigateTo(href ?? {})
  if (!href) return <TableRow {...passedProps} />
  return <TableRowClickable {...passedProps} onClick={navigateToHref} />
}
